import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Router } from "react-router-dom";

import history from "./services/history";
import Routes from "./routes";

export const App = () => (
  <ChakraProvider /* theme={theme} */>
    <Router history={history}>
      <Routes />
    </Router>
  </ChakraProvider>
);
