import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useToast } from "@chakra-ui/toast";
import * as yup from "yup";

import { Logo } from "../../Logo";
import history from "../../services/history";

const api = axios.create();

// import { ColorModeSwitcher } from "./ColorModeSwitcher";

yup.setLocale({
  // eslint-disable-next-line no-template-curly-in-string
  mixed: { required: "${path} obrigatório" },
  string: {
    email: "E-mail precisa ser válido",
    // eslint-disable-next-line no-template-curly-in-string
    min: "${path} precisa ter ao menos ${min} caracteres",
  },
});

const formSchema = yup.object().shape({
  email: yup.string().email().required().label("E-mail"),
  password: yup.string().min(6).required().label("Senha"),
});

const Login = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const toast = useToast();

  const handleClick = () => setShow(!show);

  const handleSubmit = async () => {
    try {
      await formSchema.validate({ email, password }, { abortEarly: false });

      const { data } = await api.post<{ idToken: string }>(
        "https://api.staging.missaotrader.com.br/v1/back-office/authentication",
        {
          userName: email,
          password,
        }
      );
      history.push("/home", { idToken: data.idToken });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = error.inner.reduce(
          (acc, errorItem, index, arr) => {
            if (index < arr.length - 1) {
              acc = acc + errorItem.message + ", ";
            } else {
              acc = acc + errorItem.message;
            }
            return acc;
          },
          ""
        );

        return toast({
          title: "Login",
          description: validationErrors,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }

      // const err = error as Error;
      console.log(`error`, error);
      toast({
        title: "Login",
        description: "Ocorreu um erro, tente novamente",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Center bg="#1E1A38" h="100px">
        <Logo h="80px" pointerEvents="none" />
      </Center>

      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <VStack spacing={8}>
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              mt={16}
            >
              <Box p="6">
                <Box
                  mt="1"
                  fontWeight="semibold"
                  as="h4"
                  lineHeight="tight"
                  isTruncated={false}
                >
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="gray.300" />}
                    />
                    <Input
                      type="email"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>

                  <Box mt={8}>
                    <InputGroup size="md">
                      <InputLeftElement
                        pointerEvents="none"
                        children={<LockIcon color="gray.300" />}
                      />
                      <Input
                        pr="4.5rem"
                        type={show ? "text" : "password"}
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement width="4.5rem">
                        <IconButton
                          size="md"
                          fontSize="lg"
                          variant="ghost"
                          color="current"
                          marginLeft="2"
                          onClick={handleClick}
                          icon={show ? <ViewIcon /> : <ViewOffIcon />}
                          aria-label="Mostrar senha"
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Box>

                  <Box mt={8}>
                    <Button colorScheme="blue" onClick={handleSubmit}>
                      Entrar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </VStack>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
