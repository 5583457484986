import React from "react";

import { Link } from "./styles";
// import Colors from '~/styles/colors';

export default function NotFound() {
  return (
    <>
      {/* <p style={{ color: Colors.primary }}>Página não encontrada</p> */}
      <p>Página não encontrada</p>
      <Link to="/">Voltar</Link>
    </>
  );
}
