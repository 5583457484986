import React from "react";
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { StaticContext } from "react-router";

// import DefaultLayout from "../pages/_layouts/default";
// import AuthLayout from "../pages/_layouts/auth";

// import useUser from '~/hooks/user.hook';

// import AuthLayout from '~/pages/_layouts/auth';
// import DefaultLayout from '~/pages/_layouts/default';
// import { ROLES } from '~/constants/roles';
// import { ORDER_AVAILABLE_TYPE } from '~/constants/orderAvailableType';

interface RouteWrapperProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any, StaticContext>>;
  isPublic?: boolean;
}

export default function RouterWrapper({
  component: Component,
  isPublic,
  ...rest
}: RouteWrapperProps) {
  // const { authenticated, role: userRole } = useUser();
  const authenticated = false;

  if (!authenticated && !isPublic) {
    return <Redirect to="/" />;
  }

  if (authenticated && isPublic) {
    return <Redirect to="Home" />;
  }

  // const Layout = authenticated ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        // <Layout>
        <Component {...props} />
        // </Layout>
      )}
    />
  );
}
