import React from "react";
import { Switch } from "react-router-dom";

import RouterWrapper from "./RouteWrapper";
// import BackofficeAdminRoutes from './BackofficeAdmin/BackofficeAdminRoutes.routes';
// import OriginatorRoutes from './Originator/OriginatorRoutes.routes';
// import ProducerRoutes from './Producer/ProducerRoutes.routes';

// import Login from '~/pages/Login';
// import NotFound from '~/pages/NotFound';
// import OrdersFixations from '~/pages/OrdersFixations';
// import OrderHistory from '~/pages/OrderHistory';
// import OrderProducers from '~/pages/OrderProducers';
// import OrderCounterProposals from '~/pages/OrderCounterProposals';
// import Contracts from '~/pages/Contracts';
// import DigitalContract from '~/pages/Contracts/DigitalContract';

import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";

// import useUser from '~/hooks/user.hook';
// import { ROLES } from '~/constants/roles';

export default function Routes() {
  // const { role: userRole } = useUser();

  // const RoutesRoule = () => {
  //   switch (userRole) {
  //     case ROLES.BACKOFFICE:
  //       return <BackofficeAdminRoutes />;

  //     case ROLES.PRODUCER_ADMIN:
  //       return <ProducerRoutes />;

  //     default:
  //       return <OriginatorRoutes />;
  //   }
  // };

  return (
    <Switch>
      <RouterWrapper path="/" exact isPublic component={Login} />

      <RouterWrapper
        exact
        isPublic // TODO rever
        path="/home"
        component={Home}
      />

      {/* <RouterWrapper
        exact
        path="/orderhistory/:orderId"
        component={OrderHistory}
      />

      <RouterWrapper
        exact
        path="/orderproducers/:orderId"
        component={OrderProducers}
      />

      <RouterWrapper
        exact
        path="/ordercounterproposals/:orderId"
        component={OrderCounterProposals}
      />

      <RouterWrapper
        exact
        path="/contracts/:status/:id/:name?"
        component={Contracts}
      />
      <RouterWrapper
        exact
        path="/digitalContract/:key"
        component={DigitalContract}
      /> */}

      {/* {RoutesRoule()} */}

      <RouterWrapper path="*" isPublic component={NotFound} />
    </Switch>
  );
}
