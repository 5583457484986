import styled from "styled-components";
import { Link as BaseLink } from "react-router-dom";

// import Colors from '~/styles/colors';

const Colors = {
  primary: "#7159c1",
};

export const Link = styled(BaseLink)`
  /*
  color: ${Colors.primary};
  */
  margin-top: 15px;
  transition: opacity 0.3s;
  display: block;
  &:hover {
    opacity: 0.7;
  }
`;
