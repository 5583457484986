import React, { useEffect } from "react";
import { Box, Button, Center, Grid, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router";

import { Logo } from "../../Logo";
import MyDropzone from "../../components/MyDropzone";
import history from "../../services/history";

const Home = () => {
  const { idToken } = useHistory().location.state as { idToken: string };

  const handleLogout = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!idToken) {
      handleLogout();
    }
  }, [idToken]);

  if (!idToken) {
    return null;
  }

  return (
    <>
      <Center bg="#1E1A38" h="100px">
        <Logo h="80px" pointerEvents="none" />
      </Center>

      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <VStack spacing={8}>
            <Box
              maxW="sm"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              mt={16}
            >
              <Box p="6">
                <MyDropzone />
                <Box mt="8" fontWeight="semibold" as="h4" lineHeight="tight">
                  <Button colorScheme="blue" onClick={handleLogout}>
                    Sair
                  </Button>
                </Box>
              </Box>
            </Box>
          </VStack>
        </Grid>
      </Box>
    </>
  );
};

export default Home;
