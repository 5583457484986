import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import XLSX from "xlsx";
import axios from "axios";

const api = axios.create();

interface UploadFile {
  EVENTO: string;
  HORA: string;
  PRIORIDADE: number;
  MOEDA: string;
  PAIS: string;
}

const MyDropzone = () => {
  const [contentFile, setContentFile] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const uploadFile = async (jsonParam: UploadFile[]) => {
      try {
        await api.post(
          "https://api.staging.missaotrader.com.br/v1/news",
          jsonParam
        );
      } catch (error) {
        console.log(`error`, error);
      } finally {
        setLoading(false);
        setContentFile(undefined);
      }
    };

    if (!contentFile) {
      return;
    }

    const workbook = XLSX.read(contentFile, { type: "binary" });
    const json = XLSX.utils.sheet_to_json<UploadFile>(workbook.Sheets.Sheet1);
    setLoading(true);
    uploadFile(json);
  }, [contentFile]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        const text = ev.target?.result?.toString();
        setContentFile(text);
      };

      reader.readAsText(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // maxFiles: 1,
    accept: ".csv",
    multiple: false,
  });

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Solte o arquivo aqui ...</p>
      ) : (
        <>
          <p>
            Arraste e solte o aquivo aqui, ou clique para selecionar o arquivo
          </p>
          <br />
          <em>(Somente *.csv será aceito)</em>
        </>
      )}
    </div>
  );
};

export default MyDropzone;
